import styled from '@emotion/styled';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

export const ModalForm = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,800;1,400&display=swap');
  font-family: 'Montserrat', sans-serif;
  background-image: url(/new-images/ChecklistFormBackground.png);
  background-size: contain;
  width: 600px;
  border-radius: 14px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 48px 48px 42px;
    position: relative;

    .close {
      position: absolute;
      top: -30px;
      right: -20px;
      background-color: unset;
      color: gray;
      cursor: pointer;
      font-size: 35px;
    }

    img {
      margin-bottom: 36px;
      border-radius: unset;
    }

    p {
      font-size: 38.07px;
      line-height: 46.41px;
      color: #000000;
      font-weight: 800;
      text-align: center;
      max-width: 464px;
      margin-bottom: 43px;

      span {
        font-weight: 500;
      }
    }

    input {
      width: 100%;
      height: 56px;
      border-radius: 14px;
      font-size: 18.07px;
      line-height: 22.03px;
      font-family: 'Montserrat' !important;
      background-color: white !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    input::placeholder {
      font-size: 18.07px;
      line-height: 22.03px;
      color: #fd7100;
      font-style: italic;
      font-weight: 400;
      font-family: 'Montserrat' !important;
    }

    button {
      margin-top: 39px;
      background-color: #fd7100;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      padding: 16px 52px;
      color: white;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      font-family: 'Montserrat' !important;
    }

    button:disabled,
    button[disabled] {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
      cursor: unset;
    }
  }

  @media screen and (max-width: 600px) {
    width: 95%;

    .content {
      .close {
        top: 0px;
        right: -30px;
        margin: 0;
        width: unset;
      }

      p {
        font-size: 16px;
        line-height: unset;
      }

      input {
        height: 50px;
        font-size: 16px;
        line-height: unset;
      }

      input::placeholder {
        font-size: 16px;
        line-height: unset;
      }

      button {
        font-size: 16px;
        width: 100%;
      }
    }
  }
`;
