import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Box, Flex, Image } from 'rebass';
import { COUNTRY } from '../../../../../src/configs/env';
import { postContentCss } from './postContent.css';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../layouts/main.layout';
import '../../blog-card.css';
import { BadgeLightGray, BlogTitle, VozziButton } from '../../../../global/new-global-components';
import BlogPopupChecklist from './BlogPopupChecklist';

const blogPostContainerCss = css`
  width: 780px;
  margin: 0 auto;
  padding-right: 0px;
  padding-top: 60px;

  img {
    border-radius: 22px;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    width: 680px !important;

    .post-content {
      max-width: 100% !important;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 0px;
  }
  @media screen and (max-width: 991px) {
    margin-top: -35px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
    padding-top: 50px;
  }
`;

const thumbnailLinks = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: ${theme.colors.primaryTextGray};
  color: white;

  :hover {
    background-color: ${theme.colors.vozziOrange};
  }
`;

const socialButtonPosition = css`
  position: sticky;
  position: -webkit-sticky;
  top: 42%;
  padding: 23px 0;
`;

const titleStyle = css`
  font-size: 26px;
  font-weight: 800;
  max-width: 80%;

  @media screen and (max-width: 780px) {
    width: 100%;
    max-width: 95%;
  }
`;

const readingTimeStyle = css`
  font-weight: 300;
  margin-top: 10px;
  font-size: 14px;
`;

const postDateStyle = css`
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const BlogPostContainer: React.FC<{ date: string; title: string; content: string; featuredImage: string; url?: string }> = ({
  date,
  title,
  content,
  featuredImage,
  url,
}) => {
  const { t, i18n } = useTranslation();
  const LANG = i18n.language;
  const [showPopup, setShowPopup] = useState(false);
  const renderChecklistButton = () =>
    typeof window != 'undefined' && window.location.href.includes('pripremi-se-za-putovanje-automobilom-besplatna-lista');

  return (
    <Flex css={blogPostContainerCss} flexDirection={{ _: 'column-reverse', lg: 'row' }}>
      <Box css={blogPostContainerCss} ml={{ _: 0, lg: 0 }}>
        <BadgeLightGray fontWeight="900" display="inline-flex">
          {date}
        </BadgeLightGray>
        <BlogTitle textAlign="left" fontWeight="700" fontSize="42px">
          {title}
        </BlogTitle>
        {/* <Text css={readingTimeStyle}>Vreme čitanja: 12min.</Text> */}
        <Image style={{ marginTop: 25 }} src={`https://rs.vozzi.app/${featuredImage}`} />
        <div className="post-content" css={postContentCss} dangerouslySetInnerHTML={{ __html: content }} />
        <Box>
          {renderChecklistButton() && (
            <VozziButton onClick={() => setShowPopup(true)} className="benefits-button mobile-left">
              KLIKNI ZA LISTU
            </VozziButton>
          )}

          <Flex flexDirection={{ _: 'row', lg: 'row' }} css={socialButtonPosition}>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href={`https://www.facebook.com/sharer/sharer.php?u=https://${COUNTRY}.vozzi.app/${LANG}${url}`}
            >
              <Box css={thumbnailLinks}>
                <Image src="/img/facebook.png" />
              </Box>
            </a>
            <a target="_blank" rel="noopener noreferrer nofollow" href={t('link_social_instagram')}>
              <Box css={thumbnailLinks}>
                <Image src="/img/instagram.png" />
              </Box>
            </a>
            <a
              target="_blank"
              href={`https://twitter.com/intent/tweet?text=${title}&url=https://${COUNTRY}.vozzi.app/${LANG}${url}`}
              rel="noreferrer"
            >
              <Box css={thumbnailLinks}>
                <Image src="/img/twitter.png" />
              </Box>
            </a>
            <a
              target="_blank"
              // rel="noopener noreferrer nofollow"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=https://${COUNTRY}.vozzi.app/${LANG}${url}`}
              rel="noreferrer"
            >
              <Box css={thumbnailLinks}>
                <Image src="/img/linkedin.png" />
              </Box>
            </a>
          </Flex>
        </Box>
      </Box>
      <BlogPopupChecklist show={showPopup} closePopup={setShowPopup} />
    </Flex>
  );
};
