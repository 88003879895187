import React from 'react';
import Img, { FluidObject as GatsbyImageFluid } from 'gatsby-image';
import './blog-card.css';

import { Box, Flex, Text } from 'rebass';
import { Link } from 'gatsby';
import { theme } from '../../../layouts/main.layout';
import { css } from '@emotion/core';
import { BadgeGray } from '../../../global/new-global-components';

interface BlogCardProps {
  image: GatsbyImageFluid;
  date?: string;
  title: string;
  excerpt?: string;
  link: string;
  className?: string;
  excerptClassName?: string;
}

const card = css`
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  border: none;
`;

const cardContent = css`
  text-align: left;
  color: ${theme.colors.primaryTextGray};
`;

const linkStyle = css`
  &:hover {
    text-decoration: none;
  }
`;

const imgContainer = css`
  max-height: 220px;
  overflow: hidden;
  border-radius: 22px;
`;

const imagestyle = css`
  max-width: 101%;
  width: 640px;
  height: 210px;
`;

const cardTitle = css`
  font-weight: 900;
  font-size: 20px;
  height: 80px;
  max-height: 80px;
  overflow: hidden;

  @media screen and (max-width: 780px) {
    max-height: auto;
    height: auto;
    overflow: none;
    line-height: 1.2;
    margin: 10px 0;
  }
`;

const cardDate = css`
  color: #3b3b3c;
`;

const BlogCard: React.FC<BlogCardProps> = ({ image, date, title, excerpt, link, className, excerptClassName }) => {
  return (
    <Flex css={card} className={className}>
      <Box>
        <Link to={link} css={linkStyle}>
          <Box className="imgContainer" css={imgContainer}>
            <Img fluid={image} css={imagestyle} />
          </Box>

          <Box py={3} px={0}>
            <BadgeGray display="inline-flex">{date}</BadgeGray>
            <Text css={[cardContent, cardTitle]} fontWeight={theme.fontWeights.bold}>
              {title}
            </Text>
            <div
              style={{ color: 'black', fontSize: '16px', fontWeight: 300 }}
              dangerouslySetInnerHTML={{ __html: excerpt as string }}
            ></div>
          </Box>
        </Link>
      </Box>
    </Flex>
  );
};

export default BlogCard;
