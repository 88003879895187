import React, { ReactElement } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Box, Flex, Text } from 'rebass';
import * as Sentry from '@sentry/browser';
import '../../blog-card.css';

import { theme } from '../../../../layouts/main.layout';
import { VozziPrimaryButton } from '../../../../components/layout/_/button.component';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import Dialog from '../../../../components/functional/SweetAlert';
import i18next from 'i18next';
import BlogCard from '../../../../components/layout/blogCard/BlogCard';
import { LanguageGuard } from '../../../../components/layout/LanguageGuard/LanguageGuard';
import { FluidObject as GatsbyImageFluid } from 'gatsby-image';
import { replaceHtmlEntities } from '../../../../utils/replaceHtmlEntities';

const bottomContainer = css`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  display: block;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const latestBlogContainer = css`
  display: table;
  background-color: white;
  border-radius: 4px;
  border: none;
  padding: 0;
  margin-bottom: 25px;
  box-shadow: none;
  @media screen and (max-width: 780px) {
    padding: 0;
    display: block;
  }
`;

const latestBlogHeaderContainer = css`
  margin-top: 15px;
  border-bottom: 2px solid #f47e20;
  margin-bottom: 15px;
`;

const latestBlogHeader = css`
  font-size: 22px;
  margin-bottom: 10px;
`;

const subscriptionContainer = css`
  padding: 10px 21px 20px 21px;
  margin: 0 auto;
  margin-bottom: 30px;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  border-top: 8px solid #fe7f12;
  box-shadow: 1px 10px 15px -5px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const bullet = css`
  list-style: none;

  &:before {
    content: '• ';
    color: ${theme.colors.vozziOrange};
    padding-right: 10px;
    margin-left: -22px;
  }
`;

const inputStyle = {
  borderRadius: '8px',
  border: 'solid 1px',
  borderColor: `${theme.colors.borderGray}`,
  height: '45px',
  backgroundColor: `${theme.colors.white}`,
  width: '70%',
  marginRight: '12px',
  padding: '0px 0px 0px 10px',
};

const relatedPostStyle = css`
  display: inline-block;
  float: left;
  width: 50%;
  padding: 20px;
  margin-bottom: 30px;

  @media screen and (max-width: 780px) {
    width: 100%;
    padding: 0;
    float: none;
    display: block;
  }
`;

const relatedPostsContainer = css`
  clear: both;
  display: block;
`;

const subscriptionFormTitle = css`
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 10px;
`;

const style = css`
  @media screen and (max-width: 800px) {
    width: 170px !important;
  }
`;

const getCustomDate = (date: string): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('sr-RS', { year: 'numeric', month: 'numeric', day: '2-digit' });
  return dateTimeFormat.format(new Date(date)).replace(/\//g, '.');
};

interface BottomContainerI {
  relatedPosts: Array<RelatedPostI>;
}

export interface RelatedPostI {
  id: string | number;
  date?: string;
  title: string;
  excerpt: string;
  image: GatsbyImageFluid;
  url: string;
}

export const BottomContainer: React.FC<BottomContainerI> = ({ relatedPosts }) => {
  const { t } = useTranslation();

  const handleSubmit = async (data: any) => {
    try {
      const response = await axios.post('/email-campaign-subscriptions', {
        ...data,
        type: 'web_subscription',
        comment: 'web_blog_subscription',
      });

      if (response.status === 200 || response.status === 204 || response.status === 201 || response.status === 202) {
        setTimeout(() => {
          Dialog.fire({
            title: i18next.t('response_SuccessSubscription', { email: `${data.email}` }),
            icon: 'success',
            confirmButtonColor: theme.colors.vozziVividOrange,
          });
        }, 600);
      }
    } catch (err) {
      Sentry.captureException(err);
      if (err.errors.key === 'EMAIL_CAMPAIGN_SUBSCRIPTION_EXISTS') {
        Dialog.fire({
          title: t('response_AlreadySubscribed'),
          icon: 'error',
          confirmButtonColor: theme.colors.vozziVividOrange,
        });
      } else {
        Dialog.fire({
          title: t('response_Error'),
          icon: 'error',
          confirmButtonColor: theme.colors.vozziVividOrange,
        });
      }
    }
  };

  const validationSchema = object().shape({
    email: string()
      .email()
      .matches(/^(?![^@]*([-.])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/)
      .required(),
  });

  const ErrorMessage = () => {
    return (
      <div
        style={{
          fontSize: '14px',
          width: '230px',
          marginLeft: '-10px',
          marginTop: '10px',
        }}
      >
        {`* ${t('validation_ValidEmail')}`}
      </div>
    );
  };

  return (
    <Box css={bottomContainer} mt={{ _: 4, lg: 0 }}>
      <Box css={subscriptionContainer}>
        <div style={{ width: '100%' }}>
          <Formik
            initialValues={{
              email: '',
            }}
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, errors, touched }): ReactElement => (
              <Form>
                <Text css={subscriptionFormTitle}>{t('subscription_Text')}</Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Field css={inputStyle} name="email" placeholder={t('placeholder_Email')} />

                  <VozziPrimaryButton
                    type="submit"
                    css={style}
                    style={
                      isValid
                        ? { width: '30%', cursor: 'pointer' }
                        : { width: '30%', color: '#666666', border: '1px solid #999999', backgroundColor: '#cccccc' }
                    }
                    disabled={!isValid}
                    p="5px"
                    height="45px"
                  >
                    {t('btn_SignUp')}
                  </VozziPrimaryButton>
                </Flex>
                {errors.email && touched.email ? <ErrorMessage /> : null}
              </Form>
            )}
          </Formik>
        </div>
      </Box>
      <LanguageGuard visible={[{ country: 'rs' }]}>
        <Box css={latestBlogContainer}>
          <div css={relatedPostsContainer}>
            {relatedPosts.map((link: RelatedPostI) => (
              <div css={relatedPostStyle}>
                <BlogCard
                  className="blog-card"
                  excerptClassName="excerpt"
                  key={link.id}
                  image={link.image}
                  date={link.date}
                  title={replaceHtmlEntities(link.title)}
                  excerpt={link.excerpt}
                  link={link.url}
                />
              </div>
            ))}
          </div>
        </Box>
      </LanguageGuard>
    </Box>
  );
};
