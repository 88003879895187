import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import { PageSection } from '../../../components/layout/_/PageSection/PageSection.component';
import { BlogPostContainer } from './components/BlogPostContainer.component';
import { RelatedPostI } from './components/SideContainer';
import { BlogPostI } from '../BlogPost.interface';
import { replaceHtmlEntities } from '../../../utils/replaceHtmlEntities';
import { sliceByWord } from '../../../utils/sliceByWord';
import { getFeaturedImgForBlogLayout } from '../../../helpers/getFeaturedImgForBlogLayout';
import { WpPostI } from '../../../types/WpFeedData';
import { HomePageLayout } from '../../../layouts/HomePage.layout';
import { BottomContainer } from './components/BottomContainer';
import './style.css';
import { COUNTRY } from '../../../configs/env';

interface BlogPostPropsI {
  data: {
    wordpressPost: WpPostI;
  };
  edges: any[]; // @TODO - types for contents of this array
}

const getCustomDate = (date: string): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('sr-RS', { year: 'numeric', month: 'numeric', day: '2-digit' });
  return dateTimeFormat.format(new Date(date)).replace(/\//g, '.');
};

export default (props: BlogPostPropsI): React.FC<BlogPostPropsI> | ReactElement => {
  const post = props.data.wordpressPost;
  const title = replaceHtmlEntities(post.title);

  return (
    <BlogSingleComponent
      id={post.id}
      title={title}
      content={post.content}
      featuredImg={getFeaturedImgForBlogLayout(post)}
      date={getCustomDate(post.date)}
      url={`/blog/${post.slug}`}
    />
  );
};

const removeCurrentPost = (posts: RelatedPostI[], currentSlug: string): RelatedPostI[] => {
  return posts.filter((post) => post.url !== currentSlug);
};

const BlogSingleComponent: React.FC<BlogPostI> = ({ date, title, content, featuredImg, url }) => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const content_ = content || (t('blogNoContent') as string);

  const title_ = title;
  const date_ = date;
  const url_ = url;

  const description = sliceByWord(content_, 300).replace(/<[^>]*>?/gm, '');

  const meta = {
    title: title,
    description: description,
  };

  const og = {
    image: 'https://' + COUNTRY.toLowerCase() + '.vozzi.app' + featuredImg.src,
  };

  console.log("OG IMAGE :: ", og.image);

  // Hard coded related posts
  const tempHardCodedRelatedPosts: RelatedPostI[] = [
    {
      id: 1,
      date: '27.12.2019.',
      title: t('relatedPostsOne'),
      excerpt:
        'Kao što je red i lepo vaspitanje nalaže, pravi je trenutak da vam se od srca zahvalimo na ukazanom poverenju i činjenici da ste nas prepoznali kao vrednog partnera na putu.',
      image: { src: 'https://rs.vozzi.app/static/6a3c863e7dcd349c4942ca0252a5ee69/b53a4/VOZZi-NG-blog-2019-hero.jpg' },
      url: '/sr/blog/mi-rastemo-tako-sto-vas-podizemo-hvala-vam',
    },
    {
      id: 2,
      date: '04.12.2019.',
      title: t('relatedPostsTwo'),
      excerpt:
        'Jednom kada izađete iz kuće, nikad se ne zna šta može da se dogodi. Život se odvija brzo i sigurno je da se ponekad dešavaju stvari koje niste predvideli.',
      image: { src: 'https://rs.vozzi.app/static/d7e90d80a2b3f2fbda2e37bdd4d0cd7d/b923f/Towing-Service-9-e1575468747686.webp' },
      url: '/sr/blog/da-li-cuvate-slep-sluzbu-na-brzom-biranju-svog-telefona',
    },
    {
      id: 3,
      date: '04.11.2019.',
      title: t('relatedPostsThree'),
      excerpt: 'Tokom hladnih zimskih meseci najčešći su mehanički problemi na automobilu a prvi strada akumulator.',
      image: { src: 'https://rs.vozzi.app/static/b8088bbb8f0e75c4ad2037413a69f720/e0f73/Crko-mi-akumulator-HERO.webp' },
      url: '/sr/blog/crko-mi-akumulator',
    },
    {
      id: 4,
      date: '28.5.2019.',
      title: t('relatedPostsFive'),
      excerpt: 'Evo nekoliko saveta i trikova uz pomoć kojih ćete ugoditi svom automobilu. Za uzvrat automobil vas neće izneveriti.',
      image: {
        src: 'https://rs.vozzi.app/static/faac7554af83ab2dcb5519a323afa821/b923f/hero-image-Korisni-saveti-i-trikovi-e1559075566901.webp',
      },
      url: '/sr/blog/automobil-ima-dusu',
    },
  ];

  return (
    <HomePageLayout fullWidth={true} meta={meta} og={og}>
      <PageSection>
        <Box mt={6}>
          <BlogPostContainer featuredImage={featuredImg.src} date={date_} title={title_} content={content_} url={url_} />
          <BottomContainer relatedPosts={removeCurrentPost(tempHardCodedRelatedPosts, url)} />
          {/* <SideContainer relatedPosts={removeCurrentPost(tempHardCodedRelatedPosts, url)} /> */}
        </Box>
      </PageSection>
    </HomePageLayout>
  );
};

export const postQuery = graphql`
  query ($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      excerpt
      content
      slug
      date
      categories {
        slug
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
