import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Overlay, ModalForm } from './BlogPopupChecklist.css';
import axios from 'axios';

type Props = {
  show: boolean;
  closePopup: (param: boolean) => void;
};

const BlogPopupChecklist = ({ show, closePopup }: Props) => {
  const [sent, setSent] = useState(false);

  const submitForm = async (data: { name: string; email: string }) => {
    const res = await axios.post('/newsletter-subscribe', data);

    if (res) setSent(true);
  };

  const validationSchema = object().shape({
    name: string().required(),
    email: string()
      .email()
      .matches(
        /^(?![^@]*([-.])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/,
        'Incorrect email address format'
      )
      .required(),
  });

  const ErrorMessage = ({ fieldName }: { fieldName: string }) => {
    return (
      <div
        style={{
          marginLeft: 'auto',
          fontSize: '14px',
          marginTop: fieldName === 'name' ? '-27px' : 'unset',
          marginBottom: fieldName === 'name' ? '27px' : 'unset',
        }}
      >
        {fieldName === 'name' ? '* Obavezno polje' : '* Neispravna email adresa'}
      </div>
    );
  };

  if (!show) return null;

  return (
    <>
      <Overlay />
      <ModalForm>
        <div className="content">
          <button className="close" onClick={() => closePopup(false)}>
            x
          </button>
          <img src="/new-images/logo.svg" alt="" />

          {!sent ? (
            <Formik
              validateOnBlur
              validateOnChange
              enableReinitialize
              initialValues={{ name: '', email: '' }}
              onSubmit={(data: any) => submitForm(data)}
              validationSchema={validationSchema}
            >
              {({ isValid, errors, touched, values }) => (
                <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p>
                    Besplatno <span>preuzmi svoju listu</span> za putovanje <span>i</span> lako isplaniraj put!
                  </p>

                  <Field placeholder={'Ime i prezime*...'} style={{ marginBottom: '27px' }} value={values.name} id="name" name="name" />
                  {errors.name && touched.name ? <ErrorMessage fieldName={'name'} /> : null}

                  <Field placeholder={'e-mail*...'} value={values.email} id="email" name="email" />
                  {errors.email && touched.email ? <ErrorMessage fieldName={'email'} /> : null}

                  <button type="submit" disabled={!isValid}>
                    Preuzmi
                  </button>
                </Form>
              )}
            </Formik>
          ) : (
            <div style={{ margin: '30px 0px', textAlign: 'center', color: 'black' }}>
              Email sa listom za putovanje je uspešno poslat na vašu adresu.
            </div>
          )}
        </div>
      </ModalForm>
    </>
  );
};

export default BlogPopupChecklist;
